import React from 'react';
import { Link } from 'react-router-dom';
import HeaderNew from '../common/HeaderNew'
import HeaderVR from '../common/HeaderVR'
import HeaderTop from '../common/HeaderTop'
import { getApiData,getUserId} from '../../services/Api';
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import FilterList from '../common/FilterList';
import FilterListRent from '../common/FilterListRent';

class Landing extends React.Component {

    constructor(props) {
        super(props);
     
        this.state = {
            shown:true,
          value: { min: 0, max: 51000 },
          addressList:[],
          showItems: 4,
          filterValues:{
            bedRoom_0:-1,
            bedRoom_1:0,
            bedRoom_2:0,
            maxBed:false,
            maxPrice:51000,
            minPrice:0,
            _maxPrice_:4250000,
            _minPrice_:0,
            amenities:'',
            building:'',
            neighborhood:'',
            vr:0,
            pageType:"landing",
            filterType:"Default"
        },
        };
        
      }

      componentWillMount =()=>{
        
    }
    componentDidMount() {
        window.scrollTo(0, 0);
       
      }


    render() {

       
        //console.log("this.state=>",this.state);  
        const MyMapComponent = compose(
            withProps({
                googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC-TZGgCpmB4KOB9fEXziuJE1aAGOPUNdM&libraries=geometry,drawing,places",
              
                loadingElement: <div style={{ height: `500%` }} />,
                containerElement: <div className="mapdiv" id="mapdiv" style={{ height: `100px`, width : `700px`}}/>,
                mapElement: <div style={{ height: `100%` }} />,
            }),
            lifecycle({
                componentWillMount() {
                    const refs = {}
        
                    this.setState({
                        position: null,
                        onMarkerMounted: ref => {
                            refs.marker = ref;
                        },
        
                        // onPositionChanged: () => {
                        //     var position = refs.marker.getPosition()
                        //     var lat = position.lat()
                        //     var lng = position.lng()
                        //     document.getElementById("newlat").value=lat.toString();
                        //    document.getElementById("newlng").value=lng.toString();
                            
                        // }
                    })
                },
            }),
            withScriptjs,
            withGoogleMap
        )((props) =>
      
            <GoogleMap defaultZoom={12} defaultCenter={{ lat:-1.2814065578196963, lng:36.82600366668703}} >
                {props.isMarkerShown && 
                this.state.addressList.map((mark,index)=>{
                    return  <Marker position={{ lat: parseFloat( mark.latitude),  lng: parseFloat(mark.longitude)}}   
                  draggable={false} ref={props.onMarkerMounted} onPositionChanged={props.onPositionChanged} key={index} />
                })
                }
         
            </GoogleMap>
  
            )
        return (
   
          
            <div className="w-bg FrontEnd Newskyward-design" style={{height:'100vh'}}>
             <HeaderVR/>  
             <div className="conSnapScroll" >
                    <div className="scrollarea panel-1" id="scrollCont" style={{background:'url(/landing-bg.jpg) no-repeat center center', backgroundSize:'cover'}}>
                        
                        <div className="workarea">
                    <div className='container listing-container'> 
                        <div className="listing-landing">
                          <p className="listign-logo"><img src="/logo-new.png"/></p>
                          <div className='theme_box'>
                            <p>Sales. Rentals. Investments.</p>
                          </div>
                            {/* <div id="saleFilter" >
                             <FilterList _filter_={this.state.filterValues} />
                             </div>
                                <div id="rentFilter" style={{display:'none'}}>
                                <FilterListRent _filter_={this.state.filterValues}/>
                                </div> */}
                        </div>

                    <HeaderNew/> 
                    {/* <p className="text-center"> {getUserId()>0 ?'':<Link to="/client/signup" className=" btn btn-default getstart" >Login / Get Started</Link>}</p> */}
                       </div>
                
                
                    </div>
                    <div className="goto ">                        
                          <a href="#experience" className="scroll"><img src="/arrow-down.svg"/></a>
                     </div>
                     
                    </div>
                    <div className="panel-2">
                    <div className="container workbody"  id="experience">
                            <h1>About Us</h1>
                             {/* <p className="shortInfo">We've simplified and streamlined your rental experience from viewing apartments to booking a showing to submitting your application.</p>    */}
                            <div className="experience-section">
                            <div className="shortInfo" style={{textAlign:'left'}}>
At Skyward, we believe that finding your dream home or making a successful property investment shouldn't be a daunting task. That's why our experienced team is here to guide you through every step of the real estate journey, providing exceptional customer service, unmatched skill, and personalized consulting services. Our proprietary systems and personalized roadmaps are proven to get your goals accomplished faster and with better results than our competitors. 
</div>
<div className="shortInfo" style={{textAlign:'left'}}>
EXPERIENCE: With over 20 years of experience in the real estate industry, our team has honed their skills to perfection. We have a track record of continued excellence with a strong team that completes over 600 transactions each year. We have the experience to make sure you win. We possess in-depth market knowledge, keeping a pulse on current trends and opportunities. Whether you're renting your first apartment, a seasoned investor, or looking to sell your property, our expertise will empower you to make informed decisions. We'll help you navigate the complexities of the market, negotiate the best deals, and leverage our network to your advantage. </div>

<div className="shortInfo" style={{textAlign:'left'}}>
CONSULTING: Consulting is the cornerstone of our approach. We firmly believe that every client is unique, with specific goals, preferences, and financial considerations. That's why we take the time to listen and understand your needs. We'll walk you through the options, offer valuable insights, and help you weigh the pros and cons, empowering you to make confident choices.
</div>
<div className="shortInfo" style={{textAlign:'left'}}>
FIRST-IN-CLASS MARKETING: In addition to our exceptional service, we pride ourselves on our first-in-class marketing. We understand that effectively showcasing your property requires a targeted and innovative approach. Our team utilizes the latest technologies, social media platforms, and online resources to ensure maximum exposure and reach. From captivating visuals to compelling descriptions, we'll make sure your property stands out!
</div>
<div className="shortInfo" style={{textAlign:'left'}}>
Whether you're looking to rent, buy, sell, invest, or simply explore the real estate market, Skyward is here to help. We go beyond the transaction, building lasting relationships with our clients. Our success is measured by your satisfaction, and we're dedicated to achieving outstanding results for you.
</div>
                                {/* <div className="row">
                                        <div className="col-md-4">

                                            <div className="exp-content">
                                                <p className="status">1</p>
                                                <p>Explore listings via 360* tours before your appointment </p>
                                            </div>
                                            <img src="/status1.png"/> 
                                            </div>

                                            <div className="col-md-4">
                                      
                                        <div className="exp-content">
                                        <p className="status">2</p>
                                        <p>Schedule a showing <br/>in just two clicks </p> 
                                        </div>
                                        <img src="/schedule_showing.svg"/>
                                    </div> 

                                    <div className="col-md-4">
                                     
                                        <div className="exp-content">
                                        <p className="status">3</p>
                                        <p>Submit your application, in as little as 5 minutes, all from your phone. </p> 
                                        </div>
                                       
                                        <img src="/stopwatch.svg"/> 
                                    </div>
                                
                                </div> */}
                            </div>
                          
                        </div>
                        
                        <div className="goto" style={{bottom:'-48px'}}>                        
                          <a href="#showroom" className="scroll"><img src="/arrow-down.svg"/></a>
                         </div>
                    </div>
                    <div className="showroom-section relative">
                    <div className="container workbody"  id="showroom">
                                      
						<div className="row">
                                        <div className="col-md-12 align-self-center">
                                        <h1>CORPORATE OFFICES</h1>        
                                            <br/><br/>
                                            </div></div>
                         
                             
                                <div className="row">
                                        <div className="col-md-6 align-self-center">
                                        <h1>MANHATTAN</h1>        
                                            <div className="showroom-info">
											
                                               <h4>SKYWARD</h4>
								                <p>136 Madison Avenue 5th Fl <br/>
New York, NY 10016<br/>
212.498.8890</p>
                                            </div>
                                          
                                            </div>

                                        <div className="col-md-6 align-self-center">
                                        <h1>NEW JERSEY</h1>        
                                            <div className="showroom-info">
                                               <h4>KW City Views</h4>
									
                                                <p>2200 Fletcher Ave #502<br/>
Fort Lee, NJ 07024<br/>
201.592.8900</p>
                                            </div>
                                          
                                            </div>
                                            {/* <div className="col-md-6 align-self-center">
                                            <h1>SKYWARD SHOWROOMS</h1>          
<div className="showroom-info">
   <h4>HARLEM</h4>
    <p>280 West 115th Street, 1F<br/> New York, NY 10026<br/> 212.222.2843</p>
</div>

</div> */}
                                            

                                           {/*<div className="col-md-6">
                                      
                                        <div className="showroom-info">
                                        <h4>Brooklyn OFFICE</h4>
                                        <p>463 Sterling Place Brooklyn, NY 11238 212.222.2843 </p> 
                                        </div>
                                   
                            </div> */}

                                
                                </div>
                                                     
                        </div>
                       
                       
                       
                        <div className="goto" style={{bottom:'-37px'}}>                        
                          <a href="#join" className="scroll"><img src="/arrow-down.svg"/></a>
                         </div>
                    </div>
                    <div className="showroom-section Join-section relative" style={{background:'url(/join-bg.jpg) no-repeat center center', backgroundSize:'cover', top:'-11px'}}>
                    <div className="container workbody"  id="join">
                            <h1>JOIN US</h1>                           
                            <p className="info">Are you an agent? <br/>
                            <em>Find out what makes working with Skyward so rewarding.</em></p>    
                             
                             <Link to ="/frontend/join">Find out More >></Link>  
                                                     
                        </div>
                       
                    
                    </div>
                    <footer style={{top:'-12px'}}>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="#experience" className="scroll"> The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join" > Join Us</Link></li>
                     {/* <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> */}
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li> 
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                  
                    
                    </ul>   
                   <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img className='logo_1st' src="/home-icon.png"/></li>
                       </ul>
                       {/* <img src="/NYCFC_AP3_Web.jpg"/> */}
                       <div className="logo" style={{marginTop:-36}}></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1' style={{marginTop:48}}>*Skyward Steel, LLC DBA Skyward and Skyward Real Estate is committed to adhering to the guidelines of The New York State Fair Housing Regulations. To view The Fair Housing Notice <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2024/06/nys-housing-and-anti-discrimination-notice_06.2024.pdf" target="_blank"> Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="/Legal_SOP_NYSAR_032122.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>


</p>           </div>
               </footer> 
              


              </div>
              
    </div>

                );
    }
}
export default Landing;
